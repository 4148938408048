import * as React from "react"

import MenuPage from "../components/menu-page"
import Subscribe from "../components/subscribe"
import Footer from "../components/footer"
import SEO from "../components/seo"

const DomainCheckPage = () => (
  <div>
    <SEO 
        title="Buy Domain Name - Website Domain Registration &amp; Availability" 
        description="At Sheephostingbay, we don’t just sell domain names. We provide you with 
        the best domain management services. Additionally we give you an 
        affordable domain together with each of our cloud hosting 
        packages."
    />
    <div className="sub-section-wrap">
          <MenuPage/>
    </div>

    <div className="py-12 mt-5">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <iframe src="https://domain.us.tempcloudsite.com/" className="w-full h-vh overflow-y-scroll" style={{height:"100vh"}} title="Domain search"></iframe>
        </div>
        
    </div>
    
    <Subscribe/>
    <Footer/>
  </div>
)

export default DomainCheckPage
